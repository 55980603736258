import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {OSM, BingMaps, Vector as VectorSource} from 'ol/source.js';
import {Circle as CircleStyle, Fill, Stroke, Style, Icon, Text} from 'ol/style.js';
import {transform} from 'ol/proj.js';
import Feature from 'ol/Feature.js';
import {LineString, Point} from 'ol/geom.js';
import GpsPointDTO from '../data/gpsPoint.dto';
import StopPointDTO from '../data/stopPoint.dto';
import Overlay from 'ol/Overlay.js';
import {getLength} from 'ol/sphere.js';
import Util from '../data/util';
import {StatisticsDTO} from '../data/statistics.dto';
import GoogleLayer from 'olgm/layer/Google.js';
import {defaults} from 'olgm/interaction.js';
import OLGoogleMaps from 'olgm/OLGoogleMaps.js';

type Point = [number, number];

@Component({
    selector: 'app-map-op',
    templateUrl: './map.component.html',
    styleUrls: ['../map-of-movement/map-of-movement.component.css']
})
export class MapComponent implements OnInit {
    private readonly bingKey = 'AoZZXX9IQYQsb8bh1MmEd2XvoYSmaRBZpIf7AfKpdeCA7X9npEoqyY8uzJ20oWSu ';

    private map: Map;
    private source: VectorSource;
    private vector: VectorLayer;
    private raster: TileLayer;
    private bingRoad: TileLayer;
    private bingAerial: TileLayer;
    private googleLayer = new GoogleLayer();
    private olGM: OLGoogleMaps;

    public selectedLayerId = 0;

    private view: View;
    private style: Style;

    private redStroke: Style;
    private blueStroke: Style;
    private markerStop: Style;

    private center = [105.3188, 61.5240];
    private stopsArray: Array<StopPointDTO>;

    private stopSpeedLimit = 5;
    private stopTimeDuration = 600;
    private speedLimit = 110;

    private statistics: StatisticsDTO;
    @Output() statisticsEmitter = new EventEmitter();

    public layersArray = [];

    ngOnInit() {
        this.initMap();
        this.initMapStyles();
    }

    public setSettings(speedLimit: number, stopTimeDuration: number) {
        this.speedLimit = speedLimit;
        this.stopTimeDuration = stopTimeDuration;

    }

    private initMap() {

        this.raster = new TileLayer({
            source: new OSM()
        });

        this.style = new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
                color: '#ffcc33',
                width: 2
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: '#ff1133'
                })
            })
        });

        this.source = new VectorSource();
        this.vector = new VectorLayer({
            source: this.source,
            style: this.style
        });

        const coords = this.trasformCoordinates
        (this.center.length > 0 && this.center[0] != null ?
            this.center : [39.195067, 51.667360], 'EPSG:4326', 'EPSG:3857');

        this.view = new View({
            center: coords,
            minzoom: 3,
            maxzoom: 15,
            zoom: 4,
            projection: 'EPSG:3857'
        });

        this.bingRoad = new TileLayer({
            source: new BingMaps({key: this.bingKey, imagerySet: 'Road', culture: 'ru', lang: 'ru', location: 'ru-RU'})
        });
        this.bingAerial = new TileLayer({
            source: new BingMaps({key: this.bingKey, imagerySet: 'AerialWithLabels', culture: 'ru', lang: 'ru', location: 'ru-RU'})
        });

        this.layersArray.push({id: 0, layer: this.googleLayer, name: 'Google - Streets'});
        this.layersArray.push({id: 1, layer: this.raster, name: 'Open Street'});
        this.layersArray.push({id: 2, layer: this.bingRoad, name: 'Bing - Улицы'});
        this.layersArray.push({id: 3, layer: this.bingAerial, name: 'Bing - Спутник'});


        this.map = new Map({
            title: 'Overlays',
            interactions: defaults(),
            layers: [
                this.googleLayer,
                this.raster,
                this.bingRoad,
                this.bingAerial,
                this.vector
            ],
            target: 'map',
            view: this.view
        });

        this.olGM = new OLGoogleMaps({map: this.map});
        this.olGM.activate();

        this.updateMapsView();

    }

    public updateMapsView() {
        this.layersArray.map(e => {
            // tslint:disable-next-line:triple-equals
            e.layer.setVisible(this.selectedLayerId == e.id);
        });
    }

    private initMapStyles() {
        this.redStroke = new Style({
            stroke: new Stroke({
                color: [255, 1, 1, 1.6],
                width: 8,
                lineDash: [4, 8]
            })
        });

        this.blueStroke = new Style({
            stroke: new Stroke({
                color: [1, 1, 255, 1.6],
                width: 8,
                lineDash: [4, 8]
            })
        });

        this.markerStop = new Style({
            image: new Icon(({
                anchor: [0.5, 0.96],
                crossOrigin: 'anonymous',
                src: '/assets/stop_sign.png',
                img: undefined,
                imgSize: undefined
            }))
        });


        const _this = this;

        this.map.on('click', function (evt) {
            const feature = _this.map.forEachFeatureAtPixel(evt.pixel,
                function (_feature) {
                    return _feature;
                });
            if (feature) {
                const coordinates = feature.getGeometry().getCoordinates();

                const container = document.getElementById('popup');
                const content = document.getElementById('popup-content');
                const closer = document.getElementById('popup-closer');


                const popup = new Overlay({
                    element: container,
                    positioning: 'bottom-center',
                    stopEvent: false,
                    offset: [0, -50]
                });
                _this.map.addOverlay(popup);


                content.innerHTML = '<p style="margin-top:10px; margin-left: 10px;">' +
                    'С: ' + Util.convertTimestapToDateString(feature.stopDto.timeStart)
                    + '<br>ПО: ' + Util.convertTimestapToDateString(feature.stopDto.timeEnd)
                    + '<br>Длилось: ' + (feature.stopDto.duration / 60).toFixed(2) + ' Минут.'
                    + '</p><code>'
                    + '</code>';
                popup.setPosition(coordinates);

                closer.onclick = function () {
                    popup.setPosition(undefined);
                    closer.blur();
                    return false;
                };

            } else {
                // $(element).popover('destroy');
            }
        });

    }

    private trasformCoordinates(coords, from: string, to: string): Point {
        return transform(coords, from, to);
    }

    public clearMap() {
        this.stopsArray = [];
        this.vector.getSource().clear();
        this.statistics = null;
        this.statisticsEmitter.emit(this.statistics);
    }

    private drawLines(points: Array<GpsPointDTO>) {
        for (let i = 0; i < points.length - 1; i++) {
            if (this.statistics.maxSpeed < points[i].speed) {
                this.statistics.maxSpeed = points[i].speed;
            }

            const transFormPt1 = this.trasformCoordinates
            ([points[i].longitude, points[i].latitude],
                'EPSG:4326', 'EPSG:3857');

            const transFormPt2 = this.trasformCoordinates
            ([points[i + 1].longitude, points[i + 1].latitude],
                'EPSG:4326', 'EPSG:3857');

            this.checkForStops(points[i], transFormPt1);
            // слишком большое расстояние будет выглядеть как ошибка - не рисуем
            const distance = this.distanceBetween(transFormPt1, transFormPt2);
            if (distance < 5000) {
                this.drawLine(transFormPt1, transFormPt2, points[i].speed >= this.speedLimit ? this.redStroke : this.blueStroke);
            }
        }
    }

    private setCenter() {
        if (this.vector.getSource().getFeatures().length > 0) {
            const feature = this.vector.getSource().getFeatures()[0];
            const line = (feature.getGeometry());

            this.view.setCenter(line.getCoordinates()[0]);
            this.view.setZoom(10);
        }
    }

    private drawStops() {
        for (let i = 0; i < this.stopsArray.length; i++) {
            if (this.stopsArray[i].duration >= this.stopTimeDuration) {
                this.statistics.stopsCount++;

                const iconFeature = new Feature({
                    geometry: new Point(this.stopsArray[i].latLon),
                    name: 'С: ' + this.stopsArray[i].timeStart + ' ПО: ' + this.stopsArray[i].timeEnd,
                    population: 4000,
                    rainfall: 500
                });

                iconFeature.setStyle(this.markerStop);
                iconFeature.stopDto = this.stopsArray[i];
                this.vector.getSource().addFeature(iconFeature);
            }
        }
    }

    private checkForStops(pt1: GpsPointDTO, trnsPt1: Array<any>) {

        let currentStop: StopPointDTO;

        if (this.stopsArray.length > 0 && !this.stopsArray[this.stopsArray.length - 1].timeEnd) {
            currentStop = this.stopsArray[this.stopsArray.length - 1];
        }

        if (currentStop != null && pt1.speed > this.stopSpeedLimit) {
            currentStop.timeEnd = pt1.time;
            currentStop.duration = (currentStop.timeEnd - currentStop.timeStart) / 1000;
        } else if (currentStop == null && pt1.speed <= this.stopSpeedLimit) {
            currentStop = new StopPointDTO();
            currentStop.timeStart = pt1.time;
            currentStop.latLon = trnsPt1;
            this.stopsArray.push(currentStop);
        }

    }

    public drawRoute(points: Array<GpsPointDTO>) {

        this.statistics = new StatisticsDTO();

        this.drawLines(points);
        this.setCenter();
        this.drawStops();

        this.statisticsEmitter.emit(this.statistics);
    }

    private drawMarker(pt: Array<number>, name: string) {


        const iconFeature = new Feature({
            geometry: new Point(pt),
            name: 'Null Island',
            population: 4000,
            rainfall: 500
        });
        iconFeature.setStyle(this.createMarkerStyle(name));

        this.vector.getSource().addFeature(iconFeature);

    }

    private createMarkerStyle(name) {

        const color = 'rgb(210, 115, 20)';

        return new Style({
            text: new Text({
                text: name,
                offsetY: -5,
                scale: 1.5,
                fill: new Fill({
                    color: '#fff',
                    width: 20
                }),
                stroke: new Stroke({
                    color: color,
                    width: 2,
                }),
            })
        });
    }

    public drawMarkers(vehicles: Array<any>) {

        for (let i = 0; i < vehicles.length; i++) {
            const vehicle = vehicles[i];
            this.drawMarker(this.trasformCoordinates([vehicle.lon, vehicle.lat], 'EPSG:4326', 'EPSG:3857'), vehicle.name);
        }
    }


    private drawLine(pt1: Point, pt2: Point, style: Style) {

        const geometry: LineString = new LineString(
            [pt1, pt2]
        );

        const linestringFeature = new Feature({
            geometry: geometry
        });

        linestringFeature.setStyle(style);
        this.statistics.distance += this.getLengthOfLineInKm(geometry);

        this.vector.getSource().addFeature(linestringFeature);

    }

    private getLengthOfLineInKm(line): number {
        return (Math.round(getLength(line) / 1000 * 100) / 100);
    }

    private distanceBetween(point1: Point, point2: Point): number {
        return Math.abs(point1[0] - point2[0]) + Math.abs(point1[1] - point2[1]);
    }
}
