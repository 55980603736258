/**
 * Класс для вспомогательных функций, работающих с числами
 */
export default class MathUtil {
  /**
   * Генерирует случайное целое число
   * @param min 
   * @param max 
   */
  static getRandomInteger(min : number, max : number) : number {
    return Math.floor(MathUtil.getRandomFloat(min, max));
  }
  /**
   * Генерирует случайное float число
   * @param min 
   * @param max 
   * @param shouldBeRounded 
   */
  static getRandomFloat(min : number, max : number, shouldBeRounded : boolean = false) : number {
    const result = min + Math.random() * (max - min);

    return shouldBeRounded ? this.roundToTenth(result) : result;
  }
  /**
   * Массив целых чисел
   * @param min минимум
   * @param max максимум
   * @param amount сколько чисел в массиве
   */
  static getRandomIntegers(min : number, max : number, amount : number) : number[] {
    const result : number[] = new Array(amount);

    for (var a = 0; a < amount; ++a) {
      result[a] = this.getRandomInteger(min, max);
    }

    return result;
  }
  /**
   * Массив float чисел
   * @param min минимум
   * @param max максимум
   * @param amount сколько чисел в массиве
   * @param shouldBeRounded нужно ли округлять
   */
  static getRandomFloats(min : number, max : number, amount : number, shouldBeRounded : boolean = false) : number[] {
    const result : number[] = new Array(amount);

    for (var a = 0; a < amount; ++a) {
      result[a] = this.getRandomFloat(min, max, shouldBeRounded);
    }

    return result;
  }
  /**
   * Округляет число до 1 знака после запятой
   * @param x 
   */
  static roundToTenth(x : number) : number {
    return Math.round(10 * x) / 10;
  }
}