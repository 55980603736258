import {Injectable} from '@angular/core';
import {StaticService} from './static.service';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserService} from './userService';


@Injectable()
export class ReportOptionsService {
    private saveUrl;
    private loadUrl;

    public headers;

    constructor(private http: HttpClient, private userService: UserService, private staticService: StaticService) {
        this.saveUrl = this.staticService.URLS_SETTINGS_SAVER;
        this.loadUrl = this.staticService.URLS_SETTINGS_LOADER;
        this.headers = this.staticService.HEADERS_X_FORM;
    }

    saveReportOptions(year: number, month: number, day: number, mailAddress: string, password: string,
                      mailAuth: boolean, mailTls: boolean, mailHost: string, mailPort: number): Observable<any> {
        const body = `key=report&value={"reportStartYear":` + year + `,"reportStartMonth":` + month
            + `,"dayForReport":` + day + `,"fromEmail":"` + mailAddress + `","fromEmailPassword":"` + password
            + `","mailSmtpAuth":` + mailAuth + `,"mailSmtpStarttlsEnable":` + mailTls + `,"mailSmtpHost":"` +
            mailHost + `","mailSmtpPort":` + mailPort + `}`;
        return this.http.post(this.saveUrl, body, {headers: this.headers, withCredentials: true});
    }

    saveMapOptions(globalTimeStart: number, globalTimeStop: number,
                   globalTimeOption: number, speeding: number, stopover: number): Observable<any> {
        const body = `key=global&value={"globalTimeStart":` + globalTimeStart + `,"globalTimeStop":` + globalTimeStop
            + `,"globalTimeOption":` + globalTimeOption + `,"speeding":` + speeding + `,"stopover":` + stopover + `}`;
        return this.http.post(this.saveUrl, body, {headers: this.headers, withCredentials: true});
    }


    loadReportOptions(): Observable<any> {
        const body = `key=report`;
        return this.http.post(this.loadUrl, body, {headers: this.headers, withCredentials: true});
    }

    loadMapOptions(): Observable<any> {
        const body = `key=global`;
        return this.http.post(this.loadUrl, body, {headers: this.headers, withCredentials: true});
    }

}