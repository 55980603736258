import * as _ from 'lodash';

export default class MapSettingsDTO {
    globalTimeStart: number;
    globalTimeStop: number;
    globalTimeOption: number;

    speeding: number;
    stopover: number;

    constructor(dto?: {
        globalTimeStart: number;
        globalTimeStop: number;
        globalTimeOption: number;
        speeding: number;
        stopover: number;
    }) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}
