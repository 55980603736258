import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class StaticService {

    readonly REPORT_TYPE_GENERAL = 'general';
    readonly REPORT_TYPE_ALL_DRIVERS = 'allDrivers';

    // readonly URLS_DATA_GPS = 'http://lpp/map/ajax/GPSData';
    // readonly URLS_REPORT_GROUP = 'http://lpp/map/group_report';
    // readonly URLS_DATA_DATABASE = 'http://lpp/DatabaseServlet';
    // readonly URLS_DATA_REPORT_SERVLET = 'http://lpp/ReportServlet';
    // readonly URLS_REPORT_VEHICLE = 'http://lpp/map/report/report.xls';
    // readonly URLS_ALL_VEHICLE_POSITIONS = 'http://lpp/gps/GPSData/api/v1/curpos/jsonp';
    // readonly URLS_SETTINGS_SAVER = 'http://lpp/map/ajax/SettingsSaver';
    // readonly URLS_SETTINGS_LOADER = 'http://lpp/map/ajax/SettingsLoader';

    readonly URLS_DATA_GPS = 'http://lapp.vistar.su/AndroiddevMap/ajax/GPSData';
    readonly URLS_DATA_DATABASE = 'http://lapp.vistar.su/DatabaseServlet';  
    readonly URLS_REPORT_VEHICLE = 'http://lapp.vistar.su/AndroiddevMap/report/report.xls';
    readonly URLS_DATA_REPORT_SERVLET = 'http://lapp.vistar.su/ReportServlet';
    readonly URLS_REPORT_GROUP = 'http://lapp.vistar.su/AndroiddevMap/group_report';
    readonly URLS_ALL_VEHICLE_POSITIONS = 'http://lapp.vistar.su/gps/GPSData/api/v1/curpos/jsonp';
    readonly URLS_SETTINGS_SAVER = 'http://lapp.vistar.su/AndroiddevMapBeta/ajax/SettingsSaver';
    readonly URLS_SETTINGS_LOADER = 'http://lapp.vistar.su/AndroiddevMapBeta/ajax/SettingsLoader';

    /*readonly URLS_DATA_GPS = 'http://lapp.vistar.su/AndroiddevMapBeta/ajax/GPSData';
    readonly URLS_DATA_DATABASE = 'http://localhost:8080/COManager_war_exploded/DatabaseServlet'; 
    readonly URLS_REPORT_VEHICLE = 'http://localhost:8081/AndroiddevMap_war_exploded/report/report.xls';
    readonly URLS_DATA_REPORT_SERVLET = 'http://localhost:8080/COManager_war_exploded/ReportServlet';
    readonly URLS_REPORT_GROUP = 'http://localhost:8081/AndroiddevMap_war_exploded/group_report';
    readonly URLS_ALL_VEHICLE_POSITIONS = 'http://lapp.vistar.su/gps/GPSData/api/v1/curpos/jsonp';
    readonly URLS_SETTINGS_SAVER = 'http://lapp.vistar.su/AndroiddevMapBeta/ajax/SettingsSaver';
    readonly URLS_SETTINGS_LOADER = 'http://lapp.vistar.su/AndroiddevMapBeta/ajax/SettingsLoader';*/

    readonly HEADERS_X_FORM = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json, text/plain, */*,application/x-www-form-urlencoded'
    });


}
