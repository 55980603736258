import * as _ from 'lodash';

export class UserDTO {
    success: string;
    username: string;
    role: string;
    accountId: number;
    vehicleId: number;
    haveFuelSensor: boolean;

    constructor(dto?: { success: string, username: string, role: string, accountId: number, vehicleId: number, haveFuelSensor: boolean}) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}
