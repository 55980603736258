import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Component} from '@angular/core';

@Component({
    selector: 'app-modal-confirm',
    template: `
        <div class="modal-header">
            <h4 class="modal-title" id="modal-title">Подтверждение</h4>
            <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p><strong>Вы уверены, что хотите <span class="text-primary">продолжить</span>?</strong></p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('OK')">Ok</button>
        </div>
    `
})
export class ModalConfirmComponent {
    constructor(public modal: NgbActiveModal) {
    }
}
