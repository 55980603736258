import Route from './route';
/**
 * Набор путей.
 */
export default class BunchOfRoutes {

  private _routes : Route[] = [];
  
  constructor() {
    
  }

  public isEmpty() : boolean {
    return this._routes.length == 0;
  }
  
  public set(routes : Route[]) {
    this._routes = routes;
  }

  public clear() {
    this._routes = [];
  }

  public getLengthInKilometers() : number {
    return this._routes.map(x => x.getLengthInKilometers()).reduce((x, y) => x + y, 0);
  }

  public getTimeInHours() : number {
    return this._routes.map(x => x.getTimeInHours()).reduce((x, y) => x + y, 0);
  }

  public getAverageSpeed() : number {
    const time = this.getTimeInHours();

    return this.getLengthInKilometers() / (time != 0 ? time : 1);
  }

}