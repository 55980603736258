import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import ControlMileageWeeksDTO from '../data/controlMileageWeeks.dto';
import DriverDTO from '../data/driver';
import DriverBenzinDTO from '../data/driverBenzin.dto';
import DriverFillDTO from '../data/driverFill.dto';
import DriverWasteDTO from '../data/driverWaste.dto';
import GeneralRoutingDTO from '../data/generalRouting.dto';
import MilageWorkManualDTO from '../data/milageWorkManual.dto';
import MileageData from '../data/mileage-data';
import MileageGPSManualDTO from '../data/mileageGPSManual.dto';
import MilagePrivateManualDTO from '../data/mileagePrivateManual.dto';
import Month from '../data/month';
import { ModalConfirmComponent } from '../modal/modal-confirm';
import { DriversService } from '../service/drivers.service';
import { RoutingService } from '../service/routing.service';
import { UserService } from '../service/userService';


@Component({
    selector: 'app-routing-data',
    templateUrl: './routing-data.component.html',
    styleUrls: ['./routing-data.component.css'],
    providers: []
})
export class RoutingDataComponent implements OnInit {
    /**
     * Какое-то число
     */
    readonly numberRegex = new RegExp('^\\d+\\.?\\d*$');

    readonly loadedByChecks = 0;
    readonly consumptionByNorm = 1;
    readonly consumptionByFact = 2;
    readonly personalMileage = 3;
    readonly workMileage = 4;
    readonly mileageIndication = 5;
    readonly checkedMileageIndicationWithGPS = 6;
    readonly mileageCounter = 7;

    currentDriver: DriverDTO = new DriverDTO();
    /**
     * Создаём ссылку на список месяцев, чтобы его было видно из HTML
     */
    readonly months = Month.list;

    /**
     * Выбранный год
     */
    selectedYear: number;
    /**
     * Выбранный месяц
     */
    selectedMonth: Month;
    /**
     * Отображаемые данные за текущий месяц
     */
    currentMileageData: MileageData = new MileageData();

    previousMileageData: MileageData = new MileageData();
    /**
     * Показания топлива на начало месяца (берётся из конца предыдущего месяца)
     */
    fuelValueOnTheBeginningOfMonth: number;
    controlMileage: number;

    currentDriverWasteDTO: DriverWasteDTO;
    currentDriverFillDTO: DriverFillDTO;
    currentDriverBenzinDTO: DriverBenzinDTO;

    currentMilageWorkManualDTO: MilageWorkManualDTO;
    currentMilagePrivateManualDTO: MilagePrivateManualDTO;
    currentControlMileageWeeksDTO: ControlMileageWeeksDTO;
    currentMileageGPSManualDTO: MileageGPSManualDTO;

    currentGpsMilageArray;

    generalRouting: GeneralRoutingDTO = new GeneralRoutingDTO();

    constructor(private routingService: RoutingService,
        private driversService: DriversService, public userService: UserService,
        private modalService: NgbModal,
        private calendar: NgbCalendar) {

    }


    getMonth(number: number): Month {
        return Month.list[number];
    }

    getNextMonth(): Month {
        return this.selectedMonth.getNext();
    }

    resetValues() {
        this.currentDriverWasteDTO = null;
        this.currentDriverFillDTO = null;
        this.currentDriverBenzinDTO = null;

        this.currentMilageWorkManualDTO = null;
        this.currentMilagePrivateManualDTO = null;
        this.currentControlMileageWeeksDTO = null;
        this.currentMileageGPSManualDTO = null;

        this.currentGpsMilageArray = null;
        this.fuelValueOnTheBeginningOfMonth = null;
        this.controlMileage = null;

        this.generalRouting = new GeneralRoutingDTO();
    }

    updateData(date) {
        this.selectedMonth = Month.list[date.next.month - 1];
        this.selectedYear = date.next.year;
        this.updateMileageData(false);
    }

    /**
     * Обновляет данные о пробеге
     */
    updateMileageData(recalc: boolean) {
        this.resetValues();
        this.initFuel(this.selectedMonth.getNumber(), this.selectedYear, this.currentDriver.id);
        this.initMileage(this.selectedMonth.getNumber(), this.selectedYear, this.currentDriver.id);
        this.initGPSMileage(this.selectedMonth.getNumber(), this.selectedYear, recalc, this.currentDriver.id);

    }

    updateGeneralRouting(recalcBenzinFieldNumber: number) {
        if (!this.currentMilagePrivateManualDTO || !this.currentMilageWorkManualDTO) {
            return;
        }
        this.generalRouting.week1 = this.currentMilagePrivateManualDTO.week1 / 1 + this.currentMilageWorkManualDTO.week1 / 1;
        this.generalRouting.week2 = this.currentMilagePrivateManualDTO.week2 / 1 + this.currentMilageWorkManualDTO.week2 / 1;
        this.generalRouting.week3 = this.currentMilagePrivateManualDTO.week3 / 1 + this.currentMilageWorkManualDTO.week3 / 1;
        this.generalRouting.week4 = this.currentMilagePrivateManualDTO.week4 / 1 + this.currentMilageWorkManualDTO.week4 / 1;
        this.generalRouting.week5 = this.currentMilagePrivateManualDTO.week5 / 1 + this.currentMilageWorkManualDTO.week5 / 1;
        this.generalRouting.week6 = this.currentMilagePrivateManualDTO.week6 / 1 + this.currentMilageWorkManualDTO.week6 / 1;

        if (recalcBenzinFieldNumber) {
            $('#norm-benzin-input-' + recalcBenzinFieldNumber)
                .val(this.getGeneralRoutingFieldValue(recalcBenzinFieldNumber) / 100.0 * 12.0);
        }

        this.currentControlMileageWeeksDTO.week1 = (this.currentMilagePrivateManualDTO.week1 / 1) + (this.currentMilageWorkManualDTO.week1 / 1) + (this.controlMileage / 1);
        this.currentControlMileageWeeksDTO.week2 = (this.currentMilagePrivateManualDTO.week2 / 1) + (this.currentMilageWorkManualDTO.week2 / 1) + (this.currentControlMileageWeeksDTO.week1 / 1);
        this.currentControlMileageWeeksDTO.week3 = (this.currentMilagePrivateManualDTO.week3 / 1) + (this.currentMilageWorkManualDTO.week3 / 1) + (this.currentControlMileageWeeksDTO.week2 / 1);
        this.currentControlMileageWeeksDTO.week4 = (this.currentMilagePrivateManualDTO.week4 / 1) + (this.currentMilageWorkManualDTO.week4 / 1) + (this.currentControlMileageWeeksDTO.week3 / 1);
        this.currentControlMileageWeeksDTO.week5 = (this.currentMilagePrivateManualDTO.week5 / 1) + (this.currentMilageWorkManualDTO.week5 / 1) + (this.currentControlMileageWeeksDTO.week4 / 1);
        this.currentControlMileageWeeksDTO.week6 = (this.currentMilagePrivateManualDTO.week6 / 1) + (this.currentMilageWorkManualDTO.week6 / 1) + (this.currentControlMileageWeeksDTO.week5 / 1);
    }

    getGeneralRoutingFieldValue(fieldNumber: number): number {
        switch (fieldNumber) {
            case 1:
                return this.generalRouting.week1;
            case 2:
                return this.generalRouting.week2;
            case 3:
                return this.generalRouting.week3;
            case 4:
                return this.generalRouting.week4;
            case 5:
                return this.generalRouting.week5;
            case 6:
                return this.generalRouting.week6;
            default:
                return null;
        }

    }


    initMileage(month: number, year: number, driver: number) {

        this.routingService.getMileageData(month, year, driver)
            .subscribe(data => {
                if (data.success) {
                    this.currentMilageWorkManualDTO = new DriverWasteDTO(JSON.parse(data.mileageWorkManual));
                    this.currentMilagePrivateManualDTO = new MilagePrivateManualDTO(JSON.parse(data.mileagePrivateManual));
                    this.currentControlMileageWeeksDTO = new ControlMileageWeeksDTO(JSON.parse(data.controlMileageWeeks));
                    this.currentMileageGPSManualDTO = new MileageGPSManualDTO(JSON.parse(data.mileageGPSManual));

                    this.currentMileageGPSManualDTO.week1 = Math.round(this.currentMileageGPSManualDTO.week1);
                    this.currentMileageGPSManualDTO.week2 = Math.round(this.currentMileageGPSManualDTO.week2);
                    this.currentMileageGPSManualDTO.week3 = Math.round(this.currentMileageGPSManualDTO.week3);
                    this.currentMileageGPSManualDTO.week4 = Math.round(this.currentMileageGPSManualDTO.week4);
                    this.currentMileageGPSManualDTO.week5 = Math.round(this.currentMileageGPSManualDTO.week5);
                    this.currentMileageGPSManualDTO.week6 = Math.round(this.currentMileageGPSManualDTO.week6);

                    this.currentMilageWorkManualDTO.week1 = Math.round(this.currentMilageWorkManualDTO.week1);
                    this.currentMilageWorkManualDTO.week2 = Math.round(this.currentMilageWorkManualDTO.week2);
                    this.currentMilageWorkManualDTO.week3 = Math.round(this.currentMilageWorkManualDTO.week3);
                    this.currentMilageWorkManualDTO.week4 = Math.round(this.currentMilageWorkManualDTO.week4);
                    this.currentMilageWorkManualDTO.week5 = Math.round(this.currentMilageWorkManualDTO.week5);
                    this.currentMilageWorkManualDTO.week6 = Math.round(this.currentMilageWorkManualDTO.week6);

                    this.currentMilagePrivateManualDTO.week1 = Math.round(this.currentMilagePrivateManualDTO.week1);
                    this.currentMilagePrivateManualDTO.week2 = Math.round(this.currentMilagePrivateManualDTO.week2);
                    this.currentMilagePrivateManualDTO.week3 = Math.round(this.currentMilagePrivateManualDTO.week3);
                    this.currentMilagePrivateManualDTO.week4 = Math.round(this.currentMilagePrivateManualDTO.week4);
                    this.currentMilagePrivateManualDTO.week5 = Math.round(this.currentMilagePrivateManualDTO.week5);
                    this.currentMilagePrivateManualDTO.week6 = Math.round(this.currentMilagePrivateManualDTO.week6);

                    this.controlMileage =  Math.round(data.controlMileage);

                    this.updateGeneralRouting(null);
                }

            }, error => {
                console.log(error);
            });
    }

    initGPSMileage(month: number, year: number, recalc: boolean, driver: number) {

        this.routingService.getMileageGPSData(month, year, recalc, driver)
            .subscribe(data => {
                if (data.success) {
                    this.currentMileageGPSManualDTO = new MileageGPSManualDTO();
                    this.currentGpsMilageArray = JSON.parse(data.mileage);
                    this.currentMileageGPSManualDTO.week1 = Math.round(this.currentGpsMilageArray[0]);
                    this.currentMileageGPSManualDTO.week2 = Math.round(this.currentGpsMilageArray[1]);
                    this.currentMileageGPSManualDTO.week3 = Math.round(this.currentGpsMilageArray[2]);
                    this.currentMileageGPSManualDTO.week4 = Math.round(this.currentGpsMilageArray[3]);
                    this.currentMileageGPSManualDTO.week5 = Math.round(this.currentGpsMilageArray[4]);
                    this.currentMileageGPSManualDTO.week6 = Math.round(this.currentGpsMilageArray[5]);
                    this.updateGeneralRouting(null);                  
                }

            }, error => {
                console.log(error);
            });
            
    }


    initFuel(month: number, year: number, driver: number) {
        this.routingService.getPetrolWaste(month, year, driver)
            .subscribe(data => {
                if (data.success) {
                    this.currentDriverWasteDTO = new DriverWasteDTO(JSON.parse(data.driverWaste));
                    this.currentDriverFillDTO = new DriverWasteDTO(JSON.parse(data.driverFill));
                    this.currentDriverBenzinDTO = new DriverBenzinDTO(JSON.parse(data.driverBenzin));
                    this.fuelValueOnTheBeginningOfMonth = data.controlPetrol;                

                    this.currentDriverWasteDTO.week1 = Math.round(this.currentDriverWasteDTO.week1);
                    this.currentDriverWasteDTO.week2 = Math.round(this.currentDriverWasteDTO.week2);
                    this.currentDriverWasteDTO.week3 = Math.round(this.currentDriverWasteDTO.week3);
                    this.currentDriverWasteDTO.week4 = Math.round(this.currentDriverWasteDTO.week4);
                    this.currentDriverWasteDTO.week5 = Math.round(this.currentDriverWasteDTO.week5);
                    this.currentDriverWasteDTO.week6 = Math.round(this.currentDriverWasteDTO.week6);

                    this.currentDriverFillDTO.week1 = Math.round(this.currentDriverFillDTO.week1);
                    this.currentDriverFillDTO.week2 = Math.round(this.currentDriverFillDTO.week2);
                    this.currentDriverFillDTO.week3 = Math.round(this.currentDriverFillDTO.week3);
                    this.currentDriverFillDTO.week4 = Math.round(this.currentDriverFillDTO.week4);
                    this.currentDriverFillDTO.week5 = Math.round(this.currentDriverFillDTO.week5);
                    this.currentDriverFillDTO.week6 = Math.round(this.currentDriverFillDTO.week6);
 
                    this.updateGeneralRouting(null);             
                }

            }, error => {
                console.log(error);
            });
    }


    ngOnInit() {

        this.selectedMonth = Month.list[new Date().getMonth()];
        this.selectedYear = new Date().getFullYear().valueOf();

        if (this.driversService.drivers.length > 0) {
            this.currentDriver = this.driversService.drivers[0];
        } else {
            this.currentDriver.vehicleNumber = this.userService.currentUser.vehicleId;
        }

        this.updateMileageData(false);

    }

    private requestUpdateMileage() {

        let vehicleNumber;
        if(this.userService.hasRole('anddev_admin')){
            vehicleNumber = this.currentDriver.vehicle.vehicleNumber;
        }
        else{
            vehicleNumber = this.currentDriver.vehicleNumber;
        }

        this.routingService.requestUpdateMileage(this.currentDriver.id, this.selectedMonth.getNumber(), this.selectedYear.valueOf(),
            this.controlMileage, this.fuelValueOnTheBeginningOfMonth, vehicleNumber).subscribe(data => {
                if (data.success) {
                    this.updateMileageData(false);
                }
            }, error => {
                console.log(error);
            });
    }

    saveAllData() {
        this.routingService.saveRoutes(this.currentDriver.id, this.selectedMonth.getNumber(), this.selectedYear.valueOf(),
            this.currentDriverFillDTO, this.currentDriverBenzinDTO, this.currentMilagePrivateManualDTO, 
            this.currentDriverWasteDTO, this.currentMilageWorkManualDTO, this.currentMileageGPSManualDTO).subscribe(data => {
                if (!data.success) {
                    return;
                }               
                //if (this.userService.hasRole('anddev_admin')) {
                    this.requestUpdateMileage();
                //} else {
                //    this.updateMileageData(false);
                //}


            }, error => {
                console.log(error);
            });

    }


    sendReport() {

        this.userService.getCredentials().subscribe(data => {
            if (data.success) {
                const dateStart: Date = new Date();
                dateStart.setFullYear(this.selectedYear.valueOf(), this.selectedMonth.getNumber(), 1);
                dateStart.setHours(6);
                dateStart.setMinutes(0);  
                const email = data.email != null ? data.email : 'liliya.bulanova@lappgroup.ru';         
                const dateEnd: Date = new Date();
                dateEnd.setFullYear(this.selectedYear.valueOf(), dateStart.getMonth() + 1, 0);
                dateEnd.setHours(23);
                dateEnd.setMinutes(58);

                this.routingService.sendReport(email, data.fullName, dateEnd.valueOf(), dateStart.valueOf(),
                    this.currentDriver.vehicleNumber, this.selectedMonth.getNumber(), this.selectedYear.valueOf()).subscribe(data => {
                        alert('Отправлено!');
                    }, error => {
                        alert('Ошибка!');
                        console.log(error);
                    });
            }

        }, error => {
            console.log(error);
        });
    }


    openModalConfirm() {

        const modalRef = this.modalService.open(ModalConfirmComponent, { size: 'lg', backdrop: 'static' });

        modalRef.result.then(result => {
            if (result === 'OK') {
                this.saveAllData();
            }

        }).catch(reason => {
            console.log('catch ' + reason);
        });
    }

    realWasteCheck(fieldNumber: number) {

        let diff = 0;

        switch (fieldNumber) {
            case 1: {
                const factIncomingFuel = this.fuelValueOnTheBeginningOfMonth * 1 + this.currentDriverFillDTO.week1 * 1;
                const factOutgoingFuel = this.currentDriverWasteDTO.week1 * 1;
                diff = factIncomingFuel - factOutgoingFuel;
                if (diff < 0) {
                    this.currentDriverWasteDTO.week1 = 0;
                }
                break;
            }
            case 2: {
                const factIncomingFuel = this.fuelValueOnTheBeginningOfMonth * 1 +
                    this.currentDriverFillDTO.week1 * 1 + this.currentDriverFillDTO.week2 * 1;
                const factOutgoingFuel = this.currentDriverWasteDTO.week1 * 1 + this.currentDriverWasteDTO.week2 * 1;
                diff = factIncomingFuel - factOutgoingFuel;
                if (diff < 0) {
                    this.currentDriverWasteDTO.week2 = 0;
                }
                break;
            }
            case 3: {
                const factIncomingFuel = this.fuelValueOnTheBeginningOfMonth * 1 +
                    this.currentDriverFillDTO.week1 * 1 + this.currentDriverFillDTO.week2 * 1 + this.currentDriverFillDTO.week3 * 1;
                const factOutgoingFuel = this.currentDriverWasteDTO.week1 * 1 + this.currentDriverWasteDTO.week2 * 1 + this.currentDriverWasteDTO.week3 * 1;
                diff = factIncomingFuel - factOutgoingFuel;
                if (diff < 0) {
                    this.currentDriverWasteDTO.week3 = 0;
                }
                break;
            }
            case 4: {
                const factIncomingFuel = this.fuelValueOnTheBeginningOfMonth * 1 +
                    this.currentDriverFillDTO.week1 * 1 + this.currentDriverFillDTO.week2 * 1 + this.currentDriverFillDTO.week3 * 1
                    + this.currentDriverFillDTO.week4 * 1;
                const factOutgoingFuel = this.currentDriverWasteDTO.week1 * 1 + this.currentDriverWasteDTO.week2 * 1
                    + this.currentDriverWasteDTO.week3 * 1 + this.currentDriverWasteDTO.week4 * 1;
                diff = factIncomingFuel - factOutgoingFuel;
                if (diff < 0) {
                    this.currentDriverWasteDTO.week4 = 0;
                }
                break;
            }
            case 5: {
                const factIncomingFuel = this.fuelValueOnTheBeginningOfMonth * 1 +
                    this.currentDriverFillDTO.week1 * 1 + this.currentDriverFillDTO.week2 * 1 + this.currentDriverFillDTO.week3 * 1
                    + this.currentDriverFillDTO.week4 * 1 + this.currentDriverFillDTO.week5 * 1;
                const factOutgoingFuel = this.currentDriverWasteDTO.week1 * 1 + this.currentDriverWasteDTO.week2 * 1
                    + this.currentDriverWasteDTO.week3 * 1 + this.currentDriverWasteDTO.week4 * 1 + this.currentDriverWasteDTO.week5 * 1;
                diff = factIncomingFuel - factOutgoingFuel;
                if (diff < 0) {
                    this.currentDriverWasteDTO.week5 = 0;
                }
                break;
            }
            case 6: {
                const factIncomingFuel = this.fuelValueOnTheBeginningOfMonth * 1 +
                    this.currentDriverFillDTO.week1 * 1 + this.currentDriverFillDTO.week2 * 1 + this.currentDriverFillDTO.week3 * 1
                    + this.currentDriverFillDTO.week4 * 1 + this.currentDriverFillDTO.week5 * 1 + this.currentDriverFillDTO.week6 * 1;
                const factOutgoingFuel = this.currentDriverWasteDTO.week1 * 1 + this.currentDriverWasteDTO.week2 * 1
                    + this.currentDriverWasteDTO.week3 * 1 + this.currentDriverWasteDTO.week4 * 1 + this.currentDriverWasteDTO.week5 * 1 + this.currentDriverWasteDTO.week6 * 1;
                diff = factIncomingFuel - factOutgoingFuel;
                if (diff < 0) {
                    this.currentDriverWasteDTO.week6 = 0;
                }
                break;
            }

        }
        this.alertProblem(diff);

    }

    alertProblem(diff: number) {
        if (diff >= 0) {
            return;
        }
        alert('Необходимо внести другое количество бензина, т.к. остаток получается отрицательный (' +
            diff + ' литра)');
    }

}