/**
 * Класс для вспомогательных функций, работающих с несгруппированными в объекты данными
 */
export default class Util {
    /**
     * Преобразует строки даты и времени в милисекунды.
     * @param date
     * @param time
     */
    static convertDateAndTimeToMiliseconds(date: string, time: string): number {
        const splitDate = date.split('.');
        const splitTime = time.split(':');

        const result = new Date();
        result.setDate(Number.parseInt(splitDate[0]));
        result.setMonth(Number.parseInt(splitDate[1]) - 1);
        result.setFullYear(Number.parseInt(splitDate[2]));
        result.setHours(Number.parseInt(splitTime[0]));
        result.setMinutes(Number.parseInt(splitTime[1]));
        result.setSeconds(0);

        return result.valueOf();
    }

    static convertTimestapToDateString(timestamp: number): string {

        const date: Date = new Date(timestamp);

        return date.getFullYear() + '.' + this.putZeroIfNeed(date.getMonth() + 1) + '.' + this.putZeroIfNeed(date.getDate())
            + ' ' + this.putZeroIfNeed(date.getHours()) + ':' + this.putZeroIfNeed(date.getMinutes());
    }


    static putZeroIfNeed(num: number): string {
        if (num < 10 && num >= 0) {
            return '0' + num;
        }
        return num + '';
    }

}
