import MathUtil from "./math-util";

/**
 * Объект, заполняющий таблицу данных "Данные по пробегу"
 */
export default class MileageData {
  /**
   * Сколько недель может быть в месяце
   */
  static MAX_NUMBER_OF_WEEKS_PER_MONTH = 6;

  private _loadedByChecks: number[] = new Array(MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
  public get loadedByChecks(): number[] {
    return this._loadedByChecks;
  }
  public set loadedByChecks(value: number[]) {
    this._loadedByChecks = value;
  }
  private _consumptionByNorm: number[] = new Array(MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
  public get consumptionByNorm(): number[] {
    return this._consumptionByNorm;
  }
  public set consumptionByNorm(value: number[]) {
    this._consumptionByNorm = value;
  }
  private _consumptionByFact: number[] = new Array(MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
  public get consumptionByFact(): number[] {
    return this._consumptionByFact;
  }
  public set consumptionByFact(value: number[]) {
    this._consumptionByFact = value;
  }
  private _personalMileage: number[] = new Array(MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
  public get personalMileage(): number[] {
    return this._personalMileage;
  }
  public set personalMileage(value: number[]) {
    this._personalMileage = value;
  }
  private _workMileage: number[] = new Array(MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
  public get workMileage(): number[] {
    return this._workMileage;
  }
  public set workMileage(value: number[]) {
    this._workMileage = value;
  }
  private _mileageIndication: number[] = new Array(MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
  public get mileageIndication(): number[] {
    return this._mileageIndication;
  }
  public set mileageIndication(value: number[]) {
    this._mileageIndication = value;
  }
  private _checkedMileageIndicationWithGPS: number[] = new Array(MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
  public get checkedMileageIndicationWithGPS(): number[] {
    return this._checkedMileageIndicationWithGPS;
  }
  public set checkedMileageIndicationWithGPS(value: number[]) {
    this._checkedMileageIndicationWithGPS = value;
  }
  private _mileageCounter: number[] = new Array(MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
  public get mileageCounter(): number[] {
    return this._mileageCounter;
  }
  public set mileageCounter(value: number[]) {
    this._mileageCounter = value;
  }
  private _fuelValueOnTheBeginningOfMonth: number;

  constructor() { 
    
  }

  static createMockObject() : MileageData {
    const result = new MileageData();

    result.loadedByChecks = MathUtil.getRandomFloats(50, 250, MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH, true);
    result.consumptionByNorm = MathUtil.getRandomFloats(0, 200, MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH, true);
    result.consumptionByFact = MathUtil.getRandomFloats(0, 200, MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH, true);
    result.personalMileage = MathUtil.getRandomIntegers(0, 50, MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
    result.workMileage = MathUtil.getRandomIntegers(0, 300, MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
    result.mileageIndication = MathUtil.getRandomIntegers(1234, 5678, MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
    result.checkedMileageIndicationWithGPS = MathUtil.getRandomIntegers(1234, 5678, MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
    result.mileageCounter = MathUtil.getRandomIntegers(9999, 9999, MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH);
    result.fuelValueOnTheBeginningOfMonth = MathUtil.getRandomInteger(0, 100);

    return result;
  }

  /**
   * Копирует MAX_NUMBER_OF_WEEKS_PER_MONTH элементов массива source в destination
   * @param source откуда копируем
   * @param destination куда копируем
   */
  private copyArrayWithWeeklyData(source : number[], destination : number[]) {
    for (var a = 0; a < MileageData.MAX_NUMBER_OF_WEEKS_PER_MONTH; ++a) {
      destination[a] = source[a];
    }
  }

  public get fuelValueOnTheBeginningOfMonth(): number {
    return this._fuelValueOnTheBeginningOfMonth;
  }

  public set fuelValueOnTheBeginningOfMonth(value: number) {
    this._fuelValueOnTheBeginningOfMonth = value;
  }

  public deepCopy() {
    const result = new MileageData();

    result.loadedByChecks = this.loadedByChecks.slice();
    result.consumptionByNorm = this.consumptionByNorm.slice();
    result.consumptionByFact = this.consumptionByFact.slice();
    result.personalMileage = this.personalMileage.slice();
    result.workMileage = this.workMileage.slice();
    result.mileageIndication = this.mileageIndication.slice();
    result.checkedMileageIndicationWithGPS = this.checkedMileageIndicationWithGPS.slice();
    result.mileageCounter = this.mileageCounter.slice();
    result.fuelValueOnTheBeginningOfMonth = this.fuelValueOnTheBeginningOfMonth;

    return result;
  }

}