import * as _ from 'lodash';
import VehicleDTO from './vehicle.dto';

export default class DriverDTO {

    id: number; 
    imei: string;
    vehicleNumber: number;
    organizationId: number;
    firstName: string;
    lastName: string;
    middleName: string;
    licence: string;
    licenceCategory: string;
    regNumber: number;
    regSeries: string;
    regTime: string;
    vehicleLicenceNum: string;
    model: string;
    fuelDetail: string;
    mileage: number;
    addressFeed: string;
    email: string;
    accountId: number;
    vehicle: VehicleDTO;
    login: string;
    password: string;

    constructor(dto?: {
        id: number,
        imei: string,
        vehicleNumber: number,
        organizationId: number,
        firstName: string,
        lastName: string,
        middleName: string,
        licence: string,
        licenceCategory: string,
        regNumber: number,
        regSeries: string,
        regTime: string,
        vehicleLicenceNum: string,
        model: string,
        fuelDetail: string,
        mileage: number,
        addressFeed: string,
        email: string,
        accountId: number,
        login: string,
        password: string,
    }) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}
