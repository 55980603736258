import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {PersonalInfoComponent} from './personal-info/personal-info.component';
import {RoutingDataComponent} from './routing-data/routing-data.component';
import {MapOfMovementComponent} from './map-of-movement/map-of-movement.component';
import {ReportCreatingComponent} from './report-creating/report-creating.component';
import {ReportOptionsComponent} from './report-options/report-options.component';
import {AddingEmployeeComponent} from './adding-employee/adding-employee.component';


import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
    {path: '', redirectTo: '/routing_data', pathMatch: 'full'},
    {path: 'personal_info', component: PersonalInfoComponent},
    {path: 'routing_data', component: RoutingDataComponent},
    {path: 'map_of_movement', component: MapOfMovementComponent},
    // { path: 'map_of_positions', component: MapOfPositionsComponent },
    { path: 'report_creating', component: ReportCreatingComponent },
    { path: 'report_options', component: ReportOptionsComponent },
    { path: 'adding_employee', component: AddingEmployeeComponent}
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes),NgbModule],
})
export class AppRoutingModule {
}