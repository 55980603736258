import {Component, OnInit} from '@angular/core';

import {UserService} from '../service/userService';
import {DriversService} from '../service/drivers.service';
import {Router} from '@angular/router';
import DriverDTO from '../data/driver';
import OrganizationDTO from '../data/organization.dto';
import {ModalConfirmComponent} from '../modal/modal-confirm';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-personal-info',
    templateUrl: './personal-info.component.html',
    styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent implements OnInit {

    currentDriver: DriverDTO;
    organizations: Array<OrganizationDTO>;

    constructor(private router: Router, private userService: UserService,
                private driversService: DriversService, private modalService: NgbModal) {

    }

    ngOnInit() {

        this.currentDriver = this.driversService.drivers[0];
        this.loadOrganizations();
    }

    saveAllChanges() {

        console.log("id: " + this.currentDriver.id + ".  name: " + this.currentDriver.firstName + " номер машины: " + this.currentDriver.vehicleNumber);
       
        let isChanges = confirm("Вы действительно хотите изменить данные сотрудника \"" + 
                    this.currentDriver.lastName + " " + this.currentDriver.firstName + " " + this.currentDriver.middleName +"\"?");

        if(isChanges)
        {
            this.driversService.saveDriver(this.currentDriver).subscribe(data => {

                if (data.success) {
                    alert('Изменения сохранены!');
                    this.driversService.initDrivers(); 
                    window.location.reload();
                }
                else
                {
                    alert('Ошибка! Изменения не были сохранены!\n' + data.error);
                }

            }, error => {
                alert('Ошибка! Изменения не были сохранены!');
                console.log(error);
            });
        }      
    }

    loadOrganizations() {
        this.driversService.getAllOrganizations().subscribe(data => {
            this.organizations = [];
            if (data.success) {
                data.organizations.forEach((obj, index) => {
                    this.organizations.push(new OrganizationDTO(obj));
                });
            }

        }, error => {
            console.log(error);
        });
    }

    deleteCurrentDriver() {

        console.log("id: " + this.currentDriver.id + ".  name: " + this.currentDriver.firstName + " номер машины: " +this.currentDriver.vehicleNumber);
       
        let isDelete = confirm("Вы действительно хотите удалить сотрудника \"" + 
        this.currentDriver.lastName + " " + this.currentDriver.firstName + " " + this.currentDriver.middleName +"\"?");

        if(isDelete)
        {
            this.driversService.deleteDriver(this.currentDriver).subscribe(data => {
                if (data.success) {
                    alert('Сотрудник удалён!');
                    this.driversService.initDrivers(); 
                    window.location.reload();
                }
                else 
                {              
                    alert('Ошибка! Сотрудник не был удалён!');
                }

            }, error => {
                alert('Ошибка! Сотрудник не был удалён!');
                console.log(error);
            });
        }
      
    }
}
