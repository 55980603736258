import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserService} from '../service/userService';
import {UserDTO} from '../data/user.dto';


@Directive({
    selector: '[roles]',
    inputs: ['roles']
})
export class IsAuthorizedDirective {

    constructor(private _templateRef: TemplateRef<any>,
                private _viewContainer: ViewContainerRef,
                private userService: UserService) {

    }

    @Input() set roles(allowedRoles: Array<string>) {
        let user: any;
        user = this.userService.currentUser;

        if (user == null || !this.containAllowedRoles(allowedRoles, user)) {
            this._viewContainer.clear();
        } else {
            this._viewContainer.createEmbeddedView(this._templateRef);
        }
    }

    containAllowedRoles(allowedRoles: Array<string>, user: UserDTO) {
        for (const role of allowedRoles) {
            if (user.role === role) {
                return true;
            }
        }
        return false;
    }

}
