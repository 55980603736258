import * as _ from 'lodash';
import VehicleDTO from './vehicle.dto';

export default class MileageGPSManualDTO {

    id: number;
    month: number;
    year: number;
    fuelActionId: number;

    week1: number;
    week2: number;
    week3: number;
    week4: number;
    week5: number;
    week6: number;

    constructor(dto?: {
        id: number;
        month: number;
        year: number;
        week1: number;
        week2: number;
        week3: number;
        week4: number;
        week5: number;
        week6: number;
        fuelActionId: number;
    }) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}