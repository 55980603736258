import * as _ from 'lodash';

export default class OrganizationDTO {
    id: number;
    name: string;
    okud: string;
    okpo: string;

    constructor(dto?: {
        id: number;
        name: string;
        okud: string;
        okpo: string;
    }) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}

