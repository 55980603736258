import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../service/userService';
import {CookiesService} from '../service/cookies.service';

@Component({
    selector: 'app-navboard',
    templateUrl: './navboard.component.html',
    styleUrls: ['./navboard.component.css']
})

export class NavboardComponent implements OnInit {
    selectedTab: number;
    tabs = [
        {id: 0, name: 'Персональная информация', redirectsTo: 'personal_info', role: 'anddev_admin'},
        {id: 1, name: 'Данные по пробегу', redirectsTo: 'routing_data', role: 'anddev_driver'},
        {id: 3, name: 'Карта перемещений', redirectsTo: 'map_of_movement', role: 'anddev_admin'},
        {id: 4, name: 'Где сейчас на карте', redirectsTo: 'map_of_positions', role: 'anddev_admin'},
        {id: 5, name: 'Формирование отчётов', redirectsTo: 'report_creating', role: 'anddev_admin'},
        {id: 6, name: 'Добавление сотрудника', redirectsTo: 'adding_employee', role: 'anddev_admin'}

    ];

    constructor(private router: Router, public userService: UserService, private coockieService: CookiesService,
                private activeRoute: ActivatedRoute) {   
    }

    ngOnInit() {
        const currentPath = location.pathname;
        switch (currentPath) {
            case '/personal_info':
                this.selectedTab = 1;
                break;
            case '/routing_data':
                this.selectedTab = 2;
                break;
            case '/map_of_movement':
                this.selectedTab = 3;
                break;
            case '/report_creating':
                this.selectedTab = 4;
                break;
            case '/report_options':
                this.selectedTab = 5;
                break;
            case '/adding_employee':
                this.selectedTab = 6;
                break;
            default :
                this.selectedTab = 2;
                break;
        }

    }

    changeSelectedBarNumber(newNumber: number) {
        this.selectedTab = newNumber;
    }

    public logout() {
        this.coockieService.deleteCookie('session_id');
        window.location.reload();
    }
}
