import * as _ from 'lodash';

export class StatisticsDTO {

    distance = 0;
    stopsCount = 0;
    maxSpeed = 0;

    constructor(dto?: {
        distance: number;
        stopsCount: number;
        maxSpeed;
    }) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}
