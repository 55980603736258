import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import DriverDTO from '../data/driver';
import VehicleDTO from '../data/vehicle.dto';
import { StaticService } from './static.service';
import { UserService } from './userService';

@Injectable()
export class DriversService {

    private apiUrl;

    public headers;

    public drivers: Array<DriverDTO>;
    public newDriver: DriverDTO;

    constructor(private http: HttpClient, private userService: UserService, private staticService: StaticService) {
        this.apiUrl = this.staticService.URLS_DATA_DATABASE;
        this.headers = this.staticService.HEADERS_X_FORM;
    }

    saveDriver(dto: DriverDTO): Observable<any> {

        let body = `req=reqUpdateDriver&addressFeed=` + dto.addressFeed
            + `&comments=` + dto.vehicle.comments + `&email=` + dto.email
            + `&firstName=` + dto.firstName + `&fuelDetail=` + dto.fuelDetail
            + `&id=` + dto.id + `&imei=` + dto.imei
            + `&lastName=` + dto.lastName + `&licence=` + dto.licence
            + `&licenceCategory=` + dto.licenceCategory + `&licenceNum=` + dto.vehicleLicenceNum
            + `&middleName=` + dto.middleName + `&mileage=` + dto.mileage
            + `&model=` + dto.model + `&numSim=` + dto.vehicle.numSim
            + `&organizationId=` + dto.organizationId + `&numTel=` + dto.vehicle.numTel
            + `&realNumber=` + dto.vehicle.realNumber + `&regNumber=` + dto.regNumber
            + `&regSeries=` + dto.regSeries + `&regTime=` + dto.regTime 
            + `&login=` + dto.login + `&password=` + dto.password + `&accountId=` + dto.accountId;
        body = body.replace(/\s+/g, '+');

        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});

    }

    saveNewDriver(dto: DriverDTO): Observable<any> {

        let body = `req=reqAddDriver&addressFeed=` + dto.addressFeed
            + `&comments=` + dto.vehicle.comments + `&email=` + dto.email
            + `&firstName=` + dto.firstName + `&fuelDetail=` + dto.fuelDetail
            + `&id=` + dto.id + `&imei=` + dto.imei
            + `&lastName=` + dto.lastName + `&licence=` + dto.licence
            + `&licenceCategory=` + dto.licenceCategory + `&licenceNum=` + dto.vehicleLicenceNum
            + `&middleName=` + dto.middleName + `&mileage=` + dto.mileage
            + `&model=` + dto.model + `&numSim=` + dto.vehicle.numSim
            + `&organizationId=` + dto.organizationId + `&numTel=` + dto.vehicle.numTel
            + `&realNumber=` + dto.vehicle.realNumber + `&regNumber=` + dto.regNumber
            + `&regSeries=` + dto.regSeries + `&regTime=` + dto.regTime + `&login=` + dto.login + `&password=` + dto.password;
        body = body.replace(/\s+/g, '+');

        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});

    }

    deleteDriver(dto: DriverDTO): Observable<any> {

        let body = `req=reqDeleteDriver&id=` + dto.id + `&vehicleNumber=` + dto.vehicleNumber;

        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }

    initDrivers() {

        this.getAllDrivers().subscribe(data => {
            this.drivers = [];
            if (data.success) {
                data.drivers.forEach((obj, index) => {
                    const driver = new DriverDTO(obj.driver);
                    driver.vehicle = new VehicleDTO(obj.vehicle);
                    if (this.userService.currentUser.role === 'anddev_admin' ||
                        this.userService.currentUser.accountId === driver.accountId) {
                        this.drivers.push(driver);
                    }
                });

                for (let i = 0; i < this.drivers.length; i++) {
                    for (let j = 0; j < this.drivers.length - 1; j++) {                       
                        if(this.drivers[j].lastName > this.drivers[j + 1].lastName ){
                            let timeVar = this.drivers[j];
                            this.drivers[j] = this.drivers[j + 1];
                            this.drivers[j + 1] = timeVar;
                        }                      
                    }                    
                }
            }                          
           
            this.newDriver = new DriverDTO();           
            this.newDriver.vehicle = new VehicleDTO();            
           
        }, error => {
            console.log(error);
        });        
        
    }

    private getAllDrivers(): Observable<any> {
        const body = `req=reqGetDriver`;
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }

    getAllOrganizations(): Observable<any> {
        const body = `req=reqGetOrganization`;
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }
}



