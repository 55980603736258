import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-pane',
  templateUrl: './main-pane.component.html',
  styleUrls: ['./main-pane.component.css']
})
export class MainPaneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
