import * as _ from 'lodash';


export default class StopPointDTO {

    latLon: Array<number>;

    timeStart: number;
    timeEnd: number;
    duration: number;

    constructor(dto?: {
        latLon: Array<number>;

        timeStart: number;
        timeEnd: number;
        duration: number;
    }) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}
