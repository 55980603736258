import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserDTO} from '../data/user.dto';
import {StaticService} from './static.service';

@Injectable()
export class UserService {

    private apiUrl;

    public currentUser: UserDTO = new UserDTO();
    public headers;

    constructor(private http: HttpClient, private staticService: StaticService) {
        this.apiUrl = this.staticService.URLS_DATA_DATABASE;
        this.headers = this.staticService.HEADERS_X_FORM;
    }


    getCurrUser(): Observable<any> {
        const body = `req=reqUsername`;
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }

    getCredentials(): Observable<any> {
        const body = `req=reqGetCredentials`;
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }

    hasRole(role: string): boolean {
        return this.currentUser.role === role;
    }


    // getPage(page: number, size: number): Observable<any> {
    //     return this.http.get(this.apiUrl + "/page?page=" + page + "&size=" + size, {responseType: 'json'});
    // }
    //
    // getAll(): Observable<any> {
    //     return this.http.get(this.apiUrl + "/all", {responseType: 'json'});
    // }
    //
    // saveOwner(dto: OwnerDto): Observable<any> {
    //     return this.http.post(this.apiUrl + "/save", dto, {responseType: 'json'});
    // }


}
