import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import DriverDTO from '../data/driver';
import {UserService} from './userService';
import {StaticService} from './static.service';

@Injectable()
export class ReportsService {

    private apiUrl;
    public headers;
    public drivers: Array<DriverDTO>;


    constructor(private http: HttpClient, private userService: UserService, private staticService: StaticService) {
        this.apiUrl = this.staticService.URLS_DATA_DATABASE;
        this.headers = this.staticService.HEADERS_X_FORM;
    }


    public requestVehicleReport(vehicleId: number, start: number, finish: number, month: number, year: number) {

        const url = this.staticService.URLS_REPORT_VEHICLE + '?vid=' + vehicleId + '&start=' + start + '&finish=' + finish +
            '&month=' + month + '&year=' + year;
        window.open(url, '_blank');
    }

    public requestAllDriversReport(start: number, finish: number, month: number, year: number): Observable<any> {

        const url = this.staticService.URLS_REPORT_GROUP + '?start=' + start + '&finish=' + finish +
            '&month=' + month + '&year=' + year;
        return this.http.post(url, {}, {withCredentials: true});

    }


}
