import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import DriverDTO from '../data/driver';
import DriverBenzinDTO from '../data/driverBenzin.dto';
import DriverFillDTO from '../data/driverFill.dto';
import DriverWasteDTO from '../data/driverWaste.dto';
import MilageWorkManualDTO from '../data/milageWorkManual.dto';
import MileageGPSManualDTO from '../data/mileageGPSManual.dto';
import MilagePrivateManualDTO from '../data/mileagePrivateManual.dto';
import { StaticService } from './static.service';
import { UserService } from './userService';

@Injectable()
export class RoutingService {

    private apiUrl;

    public headers;

    public drivers: Array<DriverDTO>;

    constructor(private http: HttpClient, private userService: UserService, private staticService: StaticService) {
        this.apiUrl = this.staticService.URLS_DATA_DATABASE;
        this.headers = this.staticService.HEADERS_X_FORM;
    }


    getPetrolWaste(month: number, year: number, driver: number): Observable<any> {
        let body = `req=reqGetPetrolWasteData&month=` + month + `&year=` + year;
        if (driver) {
            body += `&driver=` + driver;
        }
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }

    getMileageData(month: number, year: number, driver: number): Observable<any> {
        let body = `req=reqGetMileageData&month=` + month + `&year=` + year;
        if (driver) {
            body += `&driver=` + driver;
        }
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }

    getMileageGPSData(month: number, year: number, recalc: boolean, driver: number): Observable<any> {
        let body = `req=reqGetGPSMileageData&month=` + month + `&year=` + year + `&recalc=` + recalc;
        if (driver) {
            body += `&driver=` + driver;
        }
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }

    saveRoutes(driver: number, month: number, year: number, fillDTO: DriverFillDTO,
               normBenzin: DriverBenzinDTO, privateMileage: MilagePrivateManualDTO,
               wasteDto: DriverWasteDTO, workMileageDTO: MilageWorkManualDTO,
               gpsMileageDTO: MileageGPSManualDTO): Observable<any> {
        let driverParams = `&`;
        if (driver) {
            driverParams = `&driver=` + driver + `&`;
        }

        const body = `req=reqUpdatePetrolWaste&month=` + month + `&year=` + year + driverParams
            + this.addFillDTO(fillDTO)
            + `&`
            + this.addNormBenzin(normBenzin)
            + `&`
            + this.addPrivateMileage(privateMileage)
            + `&`
            + this.addDriverWasteDTO(wasteDto)
            + `&`
            + this.addWorkMileageDTO(workMileageDTO)
            + `&`
            + this.addGPSMileageDTO(gpsMileageDTO);
            
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }

    requestUpdateMileage(driverId: number, month: number, year: number, mileage: number, petrol, vehicleNumber: number): Observable<any> {

        const date = new Date(year, month - 1, 1, 7, 0, 0, 0);

        const body = `req=reqUpdatePetrolMileageData&month=` + month + `&year=` + year + `&date=` + date.getTime()
            + `&petrol=` + petrol + `&mileage=` + mileage + `&driverId=` + driverId + `&vehicleNumber=` + vehicleNumber;   
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }

    private addFillDTO(dto: DriverFillDTO): string {
        return `bill1=` + dto.week1
            + `&bill2=` + dto.week2
            + `&bill3=` + dto.week3
            + `&bill4=` + dto.week4
            + `&bill5=` + dto.week5
            + `&bill6=` + dto.week6;
    }

    private addNormBenzin(dto: DriverBenzinDTO): string {
        return `normBenzin1=` + dto.week1
            + `&normBenzin2=` + dto.week2
            + `&normBenzin3=` + dto.week3
            + `&normBenzin4=` + dto.week4
            + `&normBenzin5=` + dto.week5
            + `&normBenzin6=` + dto.week6;
    }

    private addPrivateMileage(dto: MilagePrivateManualDTO): string {
        return `privateMileage1=` + dto.week1
            + `&privateMileage2=` + dto.week2
            + `&privateMileage3=` + dto.week3
            + `&privateMileage4=` + dto.week4
            + `&privateMileage5=` + dto.week5
            + `&privateMileage6=` + dto.week6;
    }

    private addDriverWasteDTO(dto: DriverWasteDTO): string {
        return `waste1=` + dto.week1
            + `&waste2=` + dto.week2
            + `&waste3=` + dto.week3
            + `&waste4=` + dto.week4
            + `&waste5=` + dto.week5
            + `&waste6=` + dto.week6;
    }

    private addWorkMileageDTO(dto: MilageWorkManualDTO): string {
        return `workMileage1=` + dto.week1
            + `&workMileage2=` + dto.week2
            + `&workMileage3=` + dto.week3
            + `&workMileage4=` + dto.week4
            + `&workMileage5=` + dto.week5
            + `&workMileage6=` + dto.week6;
    }

    private addGPSMileageDTO(dto: MileageGPSManualDTO) : string {
        return `gpsMileage1=` + dto.week1
            + `&gpsMileage2=` + dto.week2
            + `&gpsMileage3=` + dto.week3
            + `&gpsMileage4=` + dto.week4
            + `&gpsMileage5=` + dto.week5
            + `&gpsMileage6=` + dto.week6;
    }

    sendReport(email: string, fullName: string, finish: number, start: number, vid: number, month: number, year: number): Observable<any> {
        const body = `month=` + month + `&year=` + year + `&email=` + email
            + `&fullName=` + fullName + `&vid=` + vid + `&start=` + start + `&finish=` + finish;

        return this.http.post(this.staticService.URLS_DATA_REPORT_SERVLET, body, {headers: this.headers, withCredentials: true});
    }

}
