import * as _ from 'lodash';

export default class GeneralRoutingDTO {

    week1: number;
    week2: number;
    week3: number;
    week4: number;
    week5: number;
    week6: number;

    constructor(dto?: {
        week1: number;
        week2: number;
        week3: number;
        week4: number;
        week5: number;
        week6: number;
    }) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}
