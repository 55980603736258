import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent, HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookiesService} from './cookies.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private coockieService: CookiesService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setHeaders: {
                headsession: 'session_id=' + this.coockieService.getCookie('session_id')
            }
        });

        return next.handle(request);
    }
}
