import {AfterViewInit, Component} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from './service/userService';
import {DriversService} from './service/drivers.service';
import {UserDTO} from './data/user.dto';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit {


    constructor(private router: Router, public userService: UserService, public driversService: DriversService) {
    }

    ngAfterViewInit(): void {
        this.initUser();
    }

    public initUser() {
        this.userService.getCurrUser().subscribe(data => {
            if (data.success) {
                this.userService.currentUser = data;
                this.driversService.initDrivers();
            }

        }, error => {
            console.log(error);
        });
    }

    private extractData(res: Response) {
        const body = JSON.stringify(res);
        return body || {};
    }

}
