import * as _ from 'lodash';

export default class ReportSettingsDTO {
    reportStartYear: number;
    reportStartMonth: number;
    dayForReport: number;

    fromEmail: string;
    fromEmailPassword: string;
    reTypefromEmailPassword: string;

    mailSmtpAuth: boolean;
    mailSmtpStarttlsEnable: boolean;

    mailSmtpHost: string;
    mailSmtpPort: number;

    constructor(dto?: {
        reportStartYear: number;
        reportStartMonth: number;
        dayForReport: number;
        fromEmail: string;
        fromEmailPassword: string;
        reTypefromEmailPassword: string;
        mailSmtpAuth: boolean;
        mailSmtpStarttlsEnable: boolean;
        mailSmtpHost: string;
        mailSmtpPort: number;
    }) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}
