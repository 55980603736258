import Month from './month';

export default class ReportOptions {
  /**
   * Выбранный день
   */
  dayOfMonthToStartReportCreation: number;
  /**
   * Выбранный месяц
   */
  monthToStartReportCreation : Month;
  /**
   * Выбранный год
   */
  yearToStartReportCreation: number;
  /**
   * С какого адреса электронной почты пользователь будет получать отчёты
   */
  emailForReports : string;
  /**
   * Пароль для emailForReports
   */
  passwordForEmailForReports : string;
  /**
   * Повторить пароль для emailForReports
   */
  retypeForPasswordForEmailForReports : string;
  /**
   * Требуется ли авторизация
   */
  authorizationIsRequired : boolean;
  /**
   * Используется ли TLS авторизация
   */
  usingTLSAuthorization : boolean;
  /**
   * SMTP.HOST для почтового ящика
   */
  chosenSMTPForMail : string;
  /**
   * Порт для chosenSMTPForMail
   */
  portForSMTP : number;

}