/**
 * Месяц
 */
export default class Month {
    /**
     * Названия
     */
    // private static readonly names = [
    //     'Январь',
    //     'Февраль',
    //     'Март',
    //     'Апрель',
    //     'Май',
    //     'Июнь',
    //     'Июль',
    //     'Август',
    //     'Сентябрь',
    //     'Октябрь',
    //     'Ноябрь',
    //     'Декабрь'
    // ];
    /**
     * Список
     */
    static readonly list: Month[] = [
        new Month(1, 'Январь'),
        new Month(2, 'Февраль'),
        new Month(3, 'Март'),
        new Month(4, 'Апрель'),
        new Month(5, 'Май'),
        new Month(6, 'Июнь'),
        new Month(7, 'Июль'),
        new Month(8, 'Август'),
        new Month(9, 'Сентябрь'),
        new Month(10, 'Октябрь'),
        new Month(11, 'Ноябрь'),
        new Month(12, 'Декабрь'),
    ];

    id: number;
    name: string;

    /**
     * Создавать новые экземпляры не надо, нужно брать из Month.list
     * @param id
     */
    private constructor(id: number, name: string) {
        this.id = id;
        this.name = name;

    }

    /**
     * Номер, начиная с 0
     */
    getNumber(): number {
        return this.id;
    }

    /**
     * Номер, начиная с 1
     */
    getNativeNumber(): number {
        return this.id + 1;
    }

    getName(): string {
        return this.name;
    }

    isFirst(): boolean {
        return this.id === 1;
    }

    isLast(): boolean {
        return this.id === 12;
    }

    getNext() {
        return Month.list[this.isLast() ? 0 : this.id];
    }

    getPrevious() {
        return Month.list[this.isFirst() ? 11 : this.id - 2];
    }
}