import {Component, OnInit} from '@angular/core';
import DriverDTO from '../data/driver';
import {DriversService} from '../service/drivers.service';
import {UserService} from '../service/userService';
import {PeriodSelectedByUser} from '../data/period-selected-by-user';
import {DateTimeAdapter} from 'ng-pick-datetime';
import Period from '../data/period';
import {ReportsService} from '../service/reports.service';


@Component({
    selector: 'app-report-creating',
    templateUrl: './report-creating.component.html',
    styleUrls: ['./report-creating.component.css']
})
export class ReportCreatingComponent implements OnInit {

    currentDriver = new DriverDTO();


    readonly today = PeriodSelectedByUser.Today;
    readonly yesterday = PeriodSelectedByUser.Yesterday;
    readonly dayBeforeYesterday = PeriodSelectedByUser.DayBeforeYesterday;
    readonly last3Days = PeriodSelectedByUser.Last3Days;
    readonly last7Days = PeriodSelectedByUser.Last7Days;
    readonly last30Days = PeriodSelectedByUser.Last30Days;
    readonly selectedByUser = PeriodSelectedByUser.SelectedByUser;
    /**
     * Релулярное выражение, соответствующее дате ДД.ММ.ГГГГ
     */
    static readonly dateRegex = new RegExp('^([1-9]|0[1-9]|[12]\\d|3[01])\\.(0?\\d|1[0-2])\\.(199\\d|20\\d\\d)$');
    /**
     * Релулярное выражение, соответствующее времени ЧЧ:ММ
     */
    static readonly timeRegex = new RegExp('^[0-2]?\\d:[0-5]?\\d$');

    /**
     * Дата начала промежутка времени, произольно выбираемого пользователем при
     * this._selectedPeriod === PeriodSelectedByUser.SelectedByUser
     */
    startDate: Date;
    /**
     * Время начала промежутка времени, произольно выбираемого пользователем при
     * this._selectedPeriod === PeriodSelectedByUser.SelectedByUser
     */
    startTime: string;
    /**
     * Дата конца промежутка времени, произольно выбираемого пользователем при
     * this._selectedPeriod === PeriodSelectedByUser.SelectedByUser
     */
    endDate: Date;
    /**
     * Время конца промежутка времени, произольно выбираемого пользователем при
     * this._selectedPeriod === PeriodSelectedByUser.SelectedByUser
     */
    endTime: string;
    /**
     * Идентификатор выбранного пользователем промежутка времени
     */
    selectedPeriod: PeriodSelectedByUser = null;


    constructor(private driversService: DriversService, private reportsService: ReportsService,
                private userService: UserService, dateTimeAdapter: DateTimeAdapter<any>) {
        dateTimeAdapter.setLocale('ru-RU');
    }

    ngOnInit() {
        if (this.driversService.drivers.length > 0) {
            this.currentDriver = null;
        } else {
            this.currentDriver.vehicleNumber = this.userService.currentUser.vehicleId;
        }

    }

    selectDriver(selectedDriver: DriverDTO) {
        this.currentDriver = selectedDriver;
    }

    protected getPeriod(): Period {
        if (this.selectedPeriod != null && this.selectedPeriod != PeriodSelectedByUser.SelectedByUser) {
            return Period.create(this.selectedPeriod);
        } else if (this.selectedPeriod == PeriodSelectedByUser.SelectedByUser) {
            return Period.createDirectly(this.startDate.valueOf(), this.endDate.valueOf());
        } else {
            console.error('Ошибка определения периода.');
            return Period.createDirectly(0, 1);
        }
    }


    createReportVehicle() {

        const mid: Date = new Date((this.getPeriod().stratTimeInMiliseconds + this.getPeriod().endTimeInMiliseconds) / 2);

        this.reportsService.requestVehicleReport(this.currentDriver.vehicleNumber,
            this.getPeriod().stratTimeInMiliseconds, this.getPeriod().endTimeInMiliseconds, mid.getMonth() + 1, mid.getFullYear()
        );
    }

    createReportAboutAllDrivers() {
        const mid: Date = new Date((this.getPeriod().stratTimeInMiliseconds + this.getPeriod().endTimeInMiliseconds) / 2);

        this.reportsService.requestAllDriversReport(
            this.getPeriod().stratTimeInMiliseconds, this.getPeriod().endTimeInMiliseconds,
            mid.getMonth() + 1, mid.getFullYear()).subscribe(data => {
            alert('OK!');

        }, error => {
            alert('Ошибка!');
            console.log(error);
        });


    }

}
