import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';


import BunchOfRoutes from '../data/bunch-of-routes';
import {DriversService} from '../service/drivers.service';
import DriverDTO from '../data/driver';
import Period from '../data/period';
import {PeriodSelectedByUser} from '../data/period-selected-by-user';
import {GpsService} from '../service/gps.service';
import {UserService} from '../service/userService';
import {MapComponent} from '../map/map.component';
import GpsPointDTO from '../data/gpsPoint.dto';
import {DateTimeAdapter} from 'ng-pick-datetime';
import {StatisticsDTO} from '../data/statistics.dto';
import MapSettingsDTO from '../data/map.options.dto';
import {ReportOptionsService} from '../service/report-options.service';

@Component({
    selector: 'app-map-of-movement',
    templateUrl: './map-of-movement.component.html',
    styleUrls: ['./map-of-movement.component.css']
})
export class MapOfMovementComponent implements OnInit {

    @ViewChild(MapComponent) mapComponent: MapComponent;

    mapSettingsDTO: MapSettingsDTO;
    statistics: StatisticsDTO;
    date2010: Date;

    /**
     * Пути, которые в данный момент показаны на карте
     */
    selectedRoutes = new BunchOfRoutes();
    /**
     * Показана ли статистика
     */
    statisticsIsShown = false;
    /**
     * Текст кнопки, показывающей/скрывающей статистику. Меняется в зависиммости от обстоятельств.
     */
    showStatisticsButtonCaption;


    readonly today = PeriodSelectedByUser.Today;
    readonly yesterday = PeriodSelectedByUser.Yesterday;
    readonly dayBeforeYesterday = PeriodSelectedByUser.DayBeforeYesterday;
    readonly last3Days = PeriodSelectedByUser.Last3Days;
    readonly last7Days = PeriodSelectedByUser.Last7Days;
    readonly last30Days = PeriodSelectedByUser.Last30Days;
    readonly selectedByUser = PeriodSelectedByUser.SelectedByUser;
    /**
     * Релулярное выражение, соответствующее дате ДД.ММ.ГГГГ
     */
    static readonly dateRegex = new RegExp('^([1-9]|0[1-9]|[12]\\d|3[01])\\.(0?\\d|1[0-2])\\.(199\\d|20\\d\\d)$');
    /**
     * Релулярное выражение, соответствующее времени ЧЧ:ММ
     */
    static readonly timeRegex = new RegExp('^[0-2]?\\d:[0-5]?\\d$');

    /**
     * Дата начала промежутка времени, произольно выбираемого пользователем при
     * this._selectedPeriod === PeriodSelectedByUser.SelectedByUser
     */
    startDate: Date;
    /**
     * Время начала промежутка времени, произольно выбираемого пользователем при
     * this._selectedPeriod === PeriodSelectedByUser.SelectedByUser
     */
    startTime: string;
    /**
     * Дата конца промежутка времени, произольно выбираемого пользователем при
     * this._selectedPeriod === PeriodSelectedByUser.SelectedByUser
     */
    endDate: Date;
    /**
     * Время конца промежутка времени, произольно выбираемого пользователем при
     * this._selectedPeriod === PeriodSelectedByUser.SelectedByUser
     */
    endTime: string;
    /**
     * Идентификатор выбранного пользователем промежутка времени
     */
    selectedPeriod: PeriodSelectedByUser = null;


    map: any;
    currentDriver: DriverDTO = new DriverDTO();

    constructor(public driversService: DriversService,
                private gpsService: GpsService, public userService: UserService,
                private reportOptionsService: ReportOptionsService,
                dateTimeAdapter: DateTimeAdapter<any>) {

        dateTimeAdapter.setLocale('ru-RU');
        this.date2010 = new Date();
        this.date2010.setUTCFullYear(2010, 0, 1);
        this.date2010.setUTCHours(0, 0, 0, 0);
    }

    /**
     * Удаляет все объекты на карте
     */
    clearMap() {
        this.mapComponent.clearMap();
        this.statisticsIsShown = false;
    }

    drawRoute(points: Array<GpsPointDTO>) {
        this.mapComponent.drawRoute(points);
    }


    driverAndPeriodWereSelected(): boolean {
        return this.currentDriver != null && this.periodWasSelected();
    }

    protected periodWasSelected(): boolean {
        return this.selectedPeriod != null;
    }

    /**
     * Возвращает период, соответствующий выбору пользователя
     */
    protected getPeriod(): Period {
        if (this.selectedPeriod != null && this.selectedPeriod != PeriodSelectedByUser.SelectedByUser) {
            return Period.create(this.selectedPeriod);
        } else if (this.selectedPeriod == PeriodSelectedByUser.SelectedByUser) {
            return Period.createDirectly(this.startDate.valueOf(), this.endDate.valueOf());
        } else {
            console.error('Ошибка определения периода.');
            return Period.createDirectly(0, 1);
        }
    }

    /**
     * Выполняется при нажатии на кнопку отображаения маршрутов
     */
    onShowRoutesButtonClicked() {
        this.clearMap();
        if (this.driverAndPeriodWereSelected()) {

            const period: Period = this.getPeriod();

            this.getGpsData(period.stratTimeInMiliseconds, period.endTimeInMiliseconds, this.currentDriver.vehicleNumber);
        }
    }

    updateShowStatisticsButtonCaption() {
        this.showStatisticsButtonCaption = this.statisticsIsShown ? 'Скрыть статистику' : 'Показать статистику';
    }

    onShowStatisticsButtonClicked() {
        this.statisticsIsShown = !this.statisticsIsShown;
        this.updateShowStatisticsButtonCaption();

        if (this.driverAndPeriodWereSelected()) {

        }
    }

    ngOnInit() {
        this.loadMapOptionsFromServer();

        if (this.driversService.drivers.length > 0) {
            this.currentDriver = this.driversService.drivers[0];
        } else {
            this.currentDriver.vehicleNumber = this.userService.currentUser.vehicleId;
        }
        // //назначаем текст кнопки
        this.updateShowStatisticsButtonCaption();

    }


    getGpsData(startTime: number, finishTime: number, vehicleId: number) {

        this.gpsService.getGpsData(startTime, finishTime, vehicleId)
            .subscribe(data => {
                this.drawRoute(data);

            }, error => {
                console.log(error);
            });

    }

    receiveStatistics(statistics: StatisticsDTO) {
        this.statistics = statistics;
    }


    showCurrentPositions() {

        this.gpsService.getCurrentPositions(new Date().valueOf()
            , this.userService.currentUser.accountId).subscribe(data => {

            data = data.replace('(', '');
            data = data.replace(')', '');
            data = data.replace(';', '');
            data = JSON.parse(data);

            const vehiclesList = [];

            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    const vehicle = {
                            id: property,
                            lat: data[property][0],
                            lon: data[property][1],
                            speed: data[property][2],
                            date: new Date(this.date2010.valueOf() + (data[property][4] * 1000)),
                            name: this.getDriverName(property)
                        }
                    ;
                    vehiclesList.push(vehicle);
                }
            }

            this.clearMap();
            this.drawMarkers(vehiclesList);

        }, error => {
            console.log(error);
        });
    }

    getDriverName(vehicleId: any): string {
        for (let i = 0; i < this.driversService.drivers.length; i++) {
            if (this.driversService.drivers[i].vehicleNumber == vehicleId) {
                return this.driversService.drivers[i].firstName + ' ' + this.driversService.drivers[i].lastName;
            }
        }
        return null;
    }

    drawMarkers(vehiclesList: Array<any>) {
        this.mapComponent.drawMarkers(vehiclesList);
    }

    loadMapOptionsFromServer() {
        this.reportOptionsService.loadMapOptions().subscribe(data => {
            this.mapSettingsDTO = new MapSettingsDTO(JSON.parse(data.global));
            this.mapComponent.setSettings(this.mapSettingsDTO.speeding * 1, this.mapSettingsDTO.stopover * 60);
        }, error => {
            console.log(error);
        });
    }

}

