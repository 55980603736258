import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {NavboardComponent} from './navboard/navboard.component';
import {MainPaneComponent} from './main-pane/main-pane.component';
import {PersonalInfoComponent} from './personal-info/personal-info.component';
// import {RoutingDataComponent} from './routing-data/routing-data.component';
// import {MapOfMovementComponent} from './map-of-movement/map-of-movement.component';
// import {MapOfPositionsComponent} from './map-of-positions/map-of-positions.component';
// import {ReportCreatingComponent} from './report-creating/report-creating.component';
// import {ReportOptionsComponent} from './report-options/report-options.component';
import {AddingEmployeeComponent} from './adding-employee/adding-employee.component';
import {UserService} from './service/userService';
import {CookiesService} from './service/cookies.service';
import {TokenInterceptor} from './service/Interceptor';
import {DriversService} from './service/drivers.service';
import {IsAuthorizedDirective} from './directive/is-authorized';
import {RoutingDataComponent} from './routing-data/routing-data.component';
import {RoutingService} from './service/routing.service';
import {MapOfMovementComponent} from './map-of-movement/map-of-movement.component';
import {GpsService} from './service/gps.service';
import {MapComponent} from './map/map.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReportCreatingComponent} from './report-creating/report-creating.component';
import {ReportsService} from './service/reports.service';
import {StaticService} from './service/static.service';
import {ReportOptionsComponent} from './report-options/report-options.component';
import {NgKnifeModule} from 'ng-knife';
import {OnlyNumberDirective} from './directive/only-numbers';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalConfirmComponent} from './modal/modal-confirm';
import {HttpClient} from '@angular/common/http';
import {ReportOptionsService} from "./service/report-options.service";
import { NgSelectModule } from '@ng-select/ng-select';
import {NgbDateStruct, NgbCalendar, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n } from './data/calendar';
@NgModule({
    imports: [
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        NgKnifeModule,
        NgbModule,
        NgSelectModule
    ],
    declarations: [
        AppComponent,
        NavboardComponent,
        MainPaneComponent,
        PersonalInfoComponent,
        RoutingDataComponent,
        MapOfMovementComponent,
        ReportCreatingComponent,
        MapComponent,
        ReportOptionsComponent,
        ModalConfirmComponent,
        IsAuthorizedDirective,
        OnlyNumberDirective,
        AddingEmployeeComponent
    ],
    providers: [UserService, CookiesService, DriversService,
        RoutingService, GpsService, ReportsService, StaticService, ReportOptionsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
    bootstrap: [AppComponent],
    entryComponents: [
        ModalConfirmComponent
    ]
})
export class AppModule {
}