import * as _ from 'lodash';

export default class VehicleDTO {
    vehicleNumber: number;
    realNumber: string;
    comments: string;
    numTel: string;
    numSim: string;

    constructor(dto?: {
        vehicleNumber: number;
        realNumber: string;
        comments: string;
        numTel: string;
        numSim: string;
    }) {
        if (dto) {
            _.assignIn(this, dto);
        }
    }
}
