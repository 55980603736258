import {Component, OnInit} from '@angular/core';

import Month from '../data/month';
import ReportOptions from '../data/report-options';
import {ReportOptionsService} from '../service/report-options.service';
import ReportSettingsDTO from '../data/report-settings.dto';
import MapSettingsDTO from '../data/map.options.dto';

@Component({
    selector: 'app-report-options',
    templateUrl: './report-options.component.html',
    styleUrls: ['./report-options.component.css']
})
export class ReportOptionsComponent implements OnInit {
    /**
     * Регулярное выражение для эл. почты
     */
    readonly emailRegex = new RegExp('^[a-z\\-\\.]+@vistar\\.su$');
    /**
     * Регулярное выражение для порта
     */
    readonly portRegex = new RegExp('^\\d{2,5}$');
    /**
     * Номера от 1 до 31
     */
    readonly days: number[] = new Array(31).fill(0).map((value, index, anArray) => index + 1);

    /**
     * Создаём ссылку на список месяцев, чтобы его было видно из HTML
     */
    readonly months = Month.list;
    /**
     * Опции
     */
    options: ReportOptions = new ReportOptions();
    mapSettings: MapSettingsDTO;
    reportOptions: ReportSettingsDTO;

    constructor(private reportOptionsService: ReportOptionsService) {
    }

    loadMapOptionsFromServer() {
        this.reportOptionsService.loadMapOptions().subscribe(data => {
            this.mapSettings = new MapSettingsDTO(JSON.parse(data.global));

        }, error => {
            console.log(error);
        });
    }

    loadOptionsFromServer() {
        this.reportOptionsService.loadReportOptions().subscribe(data => {

            try {
                this.reportOptions = new ReportSettingsDTO(JSON.parse(data.report));
            } catch (e) {
                let report = data.report;
                report = report.replace('{', '');
                report = report.replace('}', '');
                report = this.fixResponse(['"fromEmail"', '"fromEmailPassword"', '"mailSmtpHost"'], report);
                this.reportOptions = new ReportSettingsDTO(JSON.parse(report));
            }

            this.reportOptions.reTypefromEmailPassword = this.reportOptions.fromEmailPassword;
        }, error => {
            console.log(error);
        });
    }

    fixResponse(fieldsToRepair: string[], data: string): string {

        let response = '';
        const fields = data.split(',');

        for (let i = 0; i < fields.length; i++) {
            const nameValue = fields[i].split(':');
            nameValue[0] = nameValue[0].replace(new RegExp(' ', 'g'), '');
            if (fieldsToRepair.includes(nameValue[0])) {
                nameValue[1] = '"' + nameValue[1] + '"';
            }
            response += nameValue.join(':');
            if (i < fields.length - 1) {
                response += ',';
            }

        }
        return '{' + response + '}';
    }

    /**
     * Проверяет данные на корректность
     */
    checkData(): boolean {
        return this.reportOptions.fromEmailPassword === this.reportOptions.reTypefromEmailPassword;
    }

    checkDataMap(): boolean {
        return this.mapSettings.speeding >= 0 && this.mapSettings.stopover >= 0;
    }

    saveOptionsToServer() {
        this.reportOptionsService.saveReportOptions(
            this.reportOptions.reportStartYear,
            this.reportOptions.reportStartMonth,
            this.reportOptions.dayForReport,
            this.reportOptions.fromEmail,
            this.reportOptions.fromEmailPassword,
            this.reportOptions.mailSmtpAuth,
            this.reportOptions.mailSmtpStarttlsEnable,
            this.reportOptions.mailSmtpHost,
            this.reportOptions.mailSmtpPort).subscribe(data => {
            console.log(data);
        }, error => {
            console.log(error);
        });
    }


    saveMapOptions() {
        this.reportOptionsService.saveMapOptions(this.mapSettings.globalTimeStart,
            this.mapSettings.globalTimeStop, this.mapSettings.globalTimeOption,
            this.mapSettings.speeding, this.mapSettings.stopover).subscribe(data => {
            console.log(data);
        }, error => {
            console.log(error);
        });
    }

    ngOnInit() {
        this.loadOptionsFromServer();
        this.loadMapOptionsFromServer();

    }

    nextYearIsAccessabe(): boolean {
        return this.reportOptions.reportStartYear < new Date().getFullYear();
    }

    previousYearIsAccessabe(): boolean {
        return this.reportOptions.reportStartYear > 2011;
    }

    gotoNextYear() {
        if (this.nextYearIsAccessabe()) {
            this.options.yearToStartReportCreation++;
            this.reportOptions.reportStartYear++;
        }
    }

    gotoPreviousYear() {
        if (this.previousYearIsAccessabe()) {
            this.options.yearToStartReportCreation--;
            this.reportOptions.reportStartYear--;
        }
    }

}
