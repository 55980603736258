import {PeriodSelectedByUser} from './period-selected-by-user';

export default class Period {
    /**
     * Сколько милисекунд в часе
     */
    private static readonly milisecondsInHour = 1000 * 60 * 60;
    /**
     * Сколько милисекунд в дне
     */
    private static readonly milisecondsInDay = 24 * Period.milisecondsInHour;
    /**
     * Начало
     */
    private _stratTimeInMiliseconds: number;
    /**
     * Конец
     */
    private _endTimeInMiliseconds: number;

    public static createDirectly(startTimeInMiliseconds: number, endTimeInMiliseconds: number): Period {
        return new Period(startTimeInMiliseconds, endTimeInMiliseconds);
    }

    public static beginningOfADay(milli: number) {
        return milli - (milli % this.milisecondsInDay) + ((new Date).getTimezoneOffset() * 60 * 1000);
    }

    public static subtrctDays(milli: number, days: number): number {
        return milli - (days * this.milisecondsInDay);
    }


    public static create(period: PeriodSelectedByUser = null): Period {
        if (period != null) {
            //сейчас
            const now = new Date();
            const nowInMiliseconds = now.valueOf();
            //если выбран сегодняшний день
            if (period == PeriodSelectedByUser.Today) {
                return new Period(this.beginningOfADay(nowInMiliseconds), nowInMiliseconds);
            }
            //если выбран вчерашний день
            if (period == PeriodSelectedByUser.Yesterday) {

                return new Period(this.beginningOfADay(this.subtrctDays(nowInMiliseconds, 1)), this.beginningOfADay(nowInMiliseconds));
            }
            //если выбран позавчерашний день
            if (period == PeriodSelectedByUser.DayBeforeYesterday) {
                return new Period(this.beginningOfADay(this.subtrctDays(nowInMiliseconds, 2)), this.beginningOfADay(this.subtrctDays(nowInMiliseconds, 1)));
            }
            //если выбраны 3 последних дня
            if (period == PeriodSelectedByUser.Last3Days) {
                return new Period(this.beginningOfADay(this.subtrctDays(nowInMiliseconds, 3)), nowInMiliseconds);
            }
            //если выбраны 7 последних дня
            if (period == PeriodSelectedByUser.Last7Days) {
                return new Period(this.beginningOfADay(this.subtrctDays(nowInMiliseconds, 7)), nowInMiliseconds);
            }
            //если выбраны 30 последних дней
            if (period == PeriodSelectedByUser.Last30Days) {
                return new Period(this.beginningOfADay(this.subtrctDays(nowInMiliseconds, 30)), nowInMiliseconds);
            }
        }

        return new Period(0, 1);
    }

    private static setPeriodAsDaysLast(nowInMiliseconds: number, daysLast: number) {
        return new Period(
            nowInMiliseconds - daysLast * this.milisecondsInDay,
            nowInMiliseconds - (daysLast - 1) * this.milisecondsInDay
        );
    }

    private static setPeriodAsDaysLastTillNow(nowInMiliseconds: number, daysLast: number) {
        return new Period(
            nowInMiliseconds - daysLast * this.milisecondsInDay,
            nowInMiliseconds
        );
    }

    private constructor(start: number, end: number) {
        if (start > end) {
            console.error(start + ' ' + end);
            throw new Error('Промежуток времени некорректен. Конец произошёл раньше или одновременно с началом.');
        }
        this._stratTimeInMiliseconds = start;
        this._endTimeInMiliseconds = end;
    }

    public get stratTimeInMiliseconds(): number {
        return this._stratTimeInMiliseconds;
    }

    public set stratTimeInMiliseconds(value: number) {
        this._stratTimeInMiliseconds = value;
    }

    public get endTimeInMiliseconds(): number {
        return this._endTimeInMiliseconds;
    }

    public set endTimeInMiliseconds(value: number) {
        this._endTimeInMiliseconds = value;
    }

    private getLengthInMiliseconds(): number {
        return this._endTimeInMiliseconds - this._stratTimeInMiliseconds;
    }

    public intersectsWith(stratTimeInMiliseconds: number, endTimeInMiliseconds: number): boolean {
        //уже слишком поздно
        if (this.endTimeInMiliseconds < stratTimeInMiliseconds) {
            return false;
        }
        //ещё слишком рано
        if (endTimeInMiliseconds < this.stratTimeInMiliseconds) {
            return false;
        }

        return true;
    }

    public getLengthInHours(): number {
        return this.getLengthInMiliseconds() / Period.milisecondsInHour;
    }
}