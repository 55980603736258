import {NgbDateStruct, NgbCalendar, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';

const I18N_VALUES = {
    'ru': {
      weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      months: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    }
  };
  
  // Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
  // use the Angular LOCALE_ID value
  @Injectable()
  export class I18n {
    language = 'ru';
  }
  
  // Define custom service providing the months and weekdays translations
  @Injectable()
  export class CustomDatepickerI18n extends NgbDatepickerI18n {
  
    constructor(private _i18n: I18n) {
      super();
    }
  
    getWeekdayShortName(weekday: number): string {
      return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
      return I18N_VALUES[this._i18n.language].months[month - 1];
    }
    getMonthFullName(month: number): string {
      return this.getMonthShortName(month);
    }
  
    getDayAriaLabel(date: NgbDateStruct): string {
      return `${date.day}-${date.month}-${date.year}`;
    }
  }
  export class NgbdDatepickerI18n {
    model: NgbDateStruct;
  }

  
export default class Calendar {

    constructor() { 
    }

}