import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import DriverDTO from '../data/driver';
import {UserService} from './userService';
import {StaticService} from './static.service';

@Injectable()
export class GpsService {

    private apiUrl;

    public headers;

    public drivers: Array<DriverDTO>;

    constructor(private http: HttpClient, private userService: UserService, private staticService: StaticService) {
        this.apiUrl = this.staticService.URLS_DATA_GPS;
        this.headers = this.staticService.HEADERS_X_FORM;
    }


    public getGpsData(startTime: number, finishTime: number, vehicleId: number): Observable<any> {
        const body = `finish=` + finishTime + `&start=` + startTime + `&vehicleNumber=` + vehicleId;
        return this.http.post(this.apiUrl, body, {headers: this.headers, withCredentials: true});
    }


    public getCurrentPositions(startTime: number, account: number): Observable<any> {

        const url = `?account=` + account + `&_=` + startTime + `&object_id=all`;
        return this.http.get(this.staticService.URLS_ALL_VEHICLE_POSITIONS + url,
            { withCredentials: false, responseType: 'text'});
    }


}
